.button {
  @include easing(120ms, transform, true);

  --scale: 1;
  --offset-x: 0;
  --offset-y: 0;
  --pressed: 0;

  display: inline;
  outline: 1px solid transparent;
  background: var(--theme) !important;
  transform: translate3d(var(--offset-x, 0), var(--offset-y, 0), 0) scale(var(--scale)) translateY(var(--pressed));
  transform-origin: center;

  &:hover {
    outline: 1px solid var(--black) !important;
  }

  &:not(.is-sticked) {
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0,3,0.5,0);
  }

  &:active {
    --scale: 0.9;
    --pressed: 3px;
  }

  &.is-loading {
    outline: 1px solid var(--black);
    color: transparent;
    &::after {
      @include loader(1em, 1px);
    }
  }
}

button.button {
  @include no-select;
  font-family: var(--font-family--monospace);
  font-size: 70%;
  padding: 0.4em 0.4em 0.2em;
  cursor: pointer;
  vertical-align: 2px;
}

input[type=checkbox].button {
  @include reset-button;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -3px;

  cursor: pointer;

  &::after {
    @include inset(-0.25em);
    content: '';
  }

  &::before {
    content: '✓';
    opacity: 0;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    top: -0.15em;
  }

  &:checked::before {
    opacity: 1;
  }
}
