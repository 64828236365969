@keyframes appears {
  from { transform: translateY(100%); }
  to { transform: none; }
}

.view[data-view=intro] {
  --color: var(--black);

  & + & {
    padding-top: var(--gutter);
    margin-top: calc(var(--vertical-spacing) * -1);
  }

  .view__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gutter);
    align-items: center;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  h2 {
    @include font-size(xlarge);
    padding-right: var(--gutter);

    .desktop-br {
      display: block;
      @include mobile {
        display: inline;
        &::after {
          content: ' ';
        }
      }
    }
  }

  p {
    margin-top: var(--gutter);
    @include desktop {
      padding-right: var(--gutter);
    }
  }

  video, img {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
  }

  // WIP
  video {
    mix-blend-mode: multiply;
  }

  // DEBUG
  .fake-svg {
    aspect-ratio: 1;
    background: var(--lightgray);

    @include mobile {
      order: -1;
      margin-bottom: var(--gutter);
    }
  }
}
