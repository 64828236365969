.paper {
  position: relative;

  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: center;

  svg {
    width: 100%;
    height: auto;

    overflow: hidden;

    .bounding-box,
    .bounds,
    .guide {
      display: none;
    }
  }

  .paper-info {
    position: absolute;
    bottom: -1em;
    transform: translateY(100%);

    @include mobile {
      position: static;
      transform: none;
      margin: 1em 0;
    }
  }
}

.paper.debug svg {
  .guide,
  .no-export,
  .debug {
    display: block;
  }

  image {
    display: none;
  }

  rect {
    fill: none;
  }

  path {
    fill: none;
    stroke: var(--black);
  }

  .guide {
    @include easing(200ms, opacity);
    stroke: #ff44ff;
    stroke-width: 1px;
  }
  .debug { stroke: #9a1fff; }
  .paper__background { stroke: black; stroke-dasharray: 1 1; }
  .bounding-box { stroke: #4b96ff; stroke-dasharray: 1 1; }
}
