@keyframes float {
  from { transform: translate3d(var(--offset-x, 0), -50%, 0); }
  to { transform: translate3d(var(--offset-x, 0), 0, 0); }
}

.view[data-view=home] {
  @include no-select;

  --color: var(--black);

  z-index: 0;
  background-position: center;
  background-size: cover;

  position: sticky;
  top: 0;

  height: 100vh;
  @include mobile {
    height: calc(100vh - var(--vertical-spacing));
  }

  &.compact {
    height: unset;
    .menu.home-safe ~ main & .view__content {
      margin-top: var(--navbar-height);
    }
  }

  &.has-wireframe {
    --color: white;
  }

  .view__content {
    flex: 0;
    display: grid;
    place-items: center;
  }

  &.has-marquee .view__content {
    padding-bottom: var(--navbar-height);
  }

  // Improve performances
  body.has-scrolled &.has-running-matter .view_content {
    display: none;
  }

  canvas {
    @include inset(0, fixed);
    z-index: -1;
  }

  h1 {
    pointer-events: none;
    font-size: 9rem;
    line-height: 0;
    padding: 0 var(--gutter);

    @include mobile {
      padding: 0 calc(2 * var(--gutter));
    }

    svg {
      height: 1em;
      max-width: 100%;
      fill: currentColor;
    }

    svg .nut {
      vector-effect: non-scaling-stroke;
      fill: white;
    }
  }

  &.monochrome svg .nut {
    stroke: none;
    fill: var(--color);
  }

  h2 {
    @include font-size(large);
    pointer-events: none;
    margin-top: var(--gutter);
    text-align: center;
  }

  #go-down {
    --offset-x: -50%;

    pointer-events: auto;
    position: fixed;
    bottom: var(--gutter);
    left: 50%;

    will-change: transform;
    animation: float 2s ease-in-out infinite alternate;

    font-family: var(--font-family--monospace);
    text-decoration: none;

    &::after {
      @include inset(-3rem);
      content: '';
    }

    body.has-scrolled & {
      display: none;
    }
  }

  &.has-marquee #go-down {
    bottom: calc(var(--gutter) + var(--navbar-height));
  }
}
