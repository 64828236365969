.marquee {
  @include no-select;

  // Updated via JavaScript
  --offset-x: 0%;

  width: 100%;
  will-change: transform;
  overflow: hidden;

  &__content {
    @include no-select;
    cursor: default;
    width: fit-content;
    display: flex;
    white-space: nowrap;
    transform: translate3d(var(--offset-x), 0, 0);

    & > * {
      margin-right: var(--gutter);
      font-size: inherit;
    }
  }
}
