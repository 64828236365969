.view {
  position: relative;
  z-index: 1;

  width: 100vw;
  padding: var(--vertical-spacing) var(--gutter);

  background: var(--background, var(--theme));
  color: var(--color, var(--black));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  &:empty {
    display: none;
  }

  &.transparent {
    --background: var(--lightgray);
  }

  &.has-separator::before {
    content: '';
    position: absolute;
    left: var(--gutter);
    right: var(--gutter);
    top: 0;
    border-top: 1px solid var(--color, var(--black));
  }

  &__content {
    flex: 1;
    width: 100%;
    max-width: var(--max-width);
  }

  svg .accent {
    fill: var(--accent, var(--color));
  }
}
