.menu {
  @include no-select;
  @include easing(300ms, background-color);
  content: '';

  z-index: 2;
  position: fixed;

  top: 0;
  width: 100vw;
  padding: calc(var(--gutter) / 2) var(--gutter);
  background: transparent;

  color: var(--color);

  display: flex;
  gap: var(--gutter);
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  text-transform: lowercase;

  // Ensure transparency does not intercept click on matter-js canvas
  pointer-events: none;
  a { pointer-events: auto; }

  body:not(.has-scrolled) &.home-safe {
    background: var(--theme);
  }

  @include mobile {
    &.article-safe {
      background: var(--theme);
    }
  }

  @include desktop {
    body.has-scrolled &.article-safe {
      pointer-events: auto;
      .flexgroup { opacity: 0; }

      &.is-forced-visible,
      &:hover {
        background: var(--theme);
        .flexgroup { opacity: 1;}
      }
    }
  }

  a {
    @include font-size(small);
    &:not(:hover) {
      text-decoration: none;
    }
  }

  svg {
    height: 1em;
    width: auto;
    fill: var(--color);
  }

  .flexgroup {
    @include easing(300ms, opacity);
    margin-bottom: -2px;
  }

  // Increase the transition when scrolling up to avoid visual noise
  &.is-forced-visible,
  &.is-forced-visible .flexgroup {
    transition-delay: 200ms;
    transition-duration: 500ms;
  }
}
