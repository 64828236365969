.view[data-view=keywords] {
  @include no-select;

  .view__content {
    max-width: unset;
  }

  .marquee {
    @include font-size(large);
    @include mobile {
      @include font-size;
    }
  }

  .marquee + .marquee {
    margin-top: calc(var(--gutter));
  }

  svg {
    height: 1.5em;
    line-height: 0;
    width: auto;
  }
}
