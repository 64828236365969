.view[data-view=projects] {
  h2 {
    @include font-size(large);
    text-align: center;
    margin-bottom: var(--vertical-spacing);
  }

  nav {
    @include no-select;
    white-space: nowrap;
    margin-left: -0.7rem;
    margin-bottom: 1em;

    @include mobile {
      @include font-size(large);
      text-align: center;
    }
  }

  nav button {
    position: relative;
    width: var(--gutter);

    @include mobile {
      display: none;
    }

    &::after {
      @include inset(-1rem);
      content: '';
    }
  }

  nav button:first-child::after { right: 0; }
  nav button:last-child::after { left: 0; }

  .projects__dots {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    flex-wrap: wrap;
    padding: 0 var(--gutter);

    @include desktop {
      display: none;
    }
  }

  .projects__dot {
    position: relative;
    cursor: pointer;
    text-align: center;
    &::before {
      @include inset(-0.8rem);
      content: '';
    }
  }

  .projects__dot:not(.is-active) {
    opacity: 0.2;
  }

  .project {
    position: relative;
    flex: 0;
    min-width: 100%;
    scroll-snap-align: start;

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--gutter);

      @include mobile {
        display: block;
      }
    }

    figure {
      aspect-ratio: 70/100;

      @include mobile {
        margin-bottom: var(--gutter);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__abstract {
      header {
        @include font-size(small);
        margin-bottom: var(--gutter);

        border-bottom: 1px solid;
        padding-bottom: var(--gutter);

        @include mobile {
          border: 0;
          padding-bottom: 0;
        }
      }

      h3 {
        @include font-size(large);
        margin-bottom: var(--gutter);
        padding-right: var(--gutter);
        overflow: hidden;
        text-overflow: ellipsis;

        a:not(:hover) {
          text-decoration: none;
        }
      }

      p {
        margin-top: 1em;
        padding-right: var(--gutter);
      }

      footer {
        @include font-size(small);
        margin-top: var(--gutter);
      }
    }
  }
}
