.pswp {
  cursor: zoom-out;
  cursor: url('/assets/cursors/close.png'), auto;

  &__bg {
    background-color: var(--lightgray);
  }

  // We use the top-bar to intercept close clicks
  &__top-bar {
    background: none !important;
    opacity: 1 !important;
    height: 100vh;
    cursor: url('/assets/cursors/close.png'), auto;
    display: flex !important;
    justify-content: center;
  }

  &__button--close {
    background-image: url('/assets/cursors/close.png') !important;
    background-size: auto !important;
    background-position: center !important;
    height: var(--navbar-height);
    padding: 0 var(--gutter);
  }

  &__img {
    background: var(--lightgray);
    padding: var(--gutter);
  }

  &__button {
    &--arrow--left,
    &--arrow--right {
      z-index: 2;
      margin-top: 0;
      top: 0;
      height: 100vh;
      width: 33vw;

      &::before { content: none; }
    }

    &--arrow--left {
      cursor: url('/assets/cursors/prev.png'), auto;
    }

    &--arrow--right {
      cursor: url('/assets/cursors/next.png'), auto;
    }
  }
}
