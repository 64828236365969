html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font-size;

  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: var(--font-family, serif);
  background: var(--theme, var(--lightgray));

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

}

::selection {
  background: var(--color, var(--black));
  color: var(--theme, var(--lightgray));
}

a, u {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
}

hr {
  border: 0;
  border-top: 1px solid var(--color, currentColor);
  margin: var(--gutter) 0;
}

button {
  @include reset-button;
  @include no-select;
  cursor: pointer;
  font-family: var(--font-family--monospace);

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  @include no-select;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  width: 100%;
  height: auto;
}

[data-lazyload]{
  opacity: 0.1;
  background: var(--black);
  &[data-loaded=true] {}
  &[data-decoded=true] {
    @include easing(1s, opacity);
    opacity: 1;
    background: rgba(var(--black), 0.1);
  }
}

figure.has-photoswipe {
  cursor: pointer;
  cursor: zoom-in;
}

svg {
  @include no-select;
  width: auto;
  vertical-align: middle;
  fill: var(--color);
  overflow: visible;

  &, * {
    pointer-events: visibleFill;
    vector-effect: non-scaling-stroke;
    transform-box: fill-box;
  }
}

.flexgroup {
  display: flex;
  gap: var(--gutter);
}
