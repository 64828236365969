@font-face {
  font-family: 'Friction Mono';
  font-display: auto;
  src:
    url('/assets/fonts/friction-mono.woff2') format('woff2'),
    url('/assets/fonts/friction-mono.woff') format('woff'),
    url('/assets/fonts/friction-mono.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Styrene';
  font-display: auto;
  src:
    url('/assets/fonts/styrenea-regular.woff2') format('woff2'),
    url('/assets/fonts/styrenea-regular.woff') format('woff'),
    url('/assets/fonts/styrenea-regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Styrene';
  font-display: auto;
  src:
    url('/assets/fonts/styrenea-regularitalic.woff2') format('woff2'),
    url('/assets/fonts/styrenea-regularitalic.woff') format('woff'),
    url('/assets/fonts/styrenea-regularitalic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Styrene';
  font-display: auto;
  src:
    url('/assets/fonts/styrenea-medium.woff2') format('woff2'),
    url('/assets/fonts/styrenea-medium.woff') format('woff'),
    url('/assets/fonts/styrenea-medium.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}


@font-face {
  font-family: 'Styrene';
  font-display: auto;
  src:
    url('/assets/fonts/styrenea-mediumitalic.woff2') format('woff2'),
    url('/assets/fonts/styrenea-mediumitalic.woff') format('woff'),
    url('/assets/fonts/styrenea-mediumitalic.otf') format('opentype');
  font-style: italic;
  font-weight: bold;
}
