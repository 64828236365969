.view[data-view=footer] {
  padding: var(--gutter);

  .view__content {
    max-width: unset;
  }

  a:not(:hover) {
    text-decoration: none;
  }

  footer {
    @include font-size(small);
    display: flex;
    justify-content: space-between;
    text-transform: lowercase;
    white-space: nowrap;
  }

  #go-up {
    font-family: var(--font-family--monospace);
    text-decoration: none;
    @include mobile {
      display: none;
    }
  }

  @include mobile {
    a[hreflang] {
      font-size: 0;
      &::after {
        @include font-size(small);
        content: attr(hreflang);
      }
    }
  }
}
