.article {
  @include font-size;
  margin: 0 auto;
  max-width: var(--max-width--article);

  & > *:first-child {
    margin-top: 0;
  }

  a {
    color: var(--accent);
    &:hover {
      text-decoration: none;
    }
  }

  // Heading levels should start at h3 on article (h1 = website, h2 = page)
  h1 {}
  h2 {}

  h3 {
    font-size: 2em;
    line-height: 1.2;
  }

  h4 {
    font-size: 1.5em;
    line-height: 1.2;
  }

  h5 {
    font-size: 1em;
    line-height: 1.2;
    font-weight: bold;
  }

  h6 {
    font-size: 0.8em;
    line-height: 1.2;
    font-weight: bold;
  }

  h3, h4, h5, h6 {
    margin: 3em 0 1em;
  }

  table, img, ul, ol, p, blockquote {
    margin: 1em 0;
  }

  img, iframe {
    border: 0;
    background: black;
    display: block;
    margin: 2em auto;
    vertical-align: bottom;
  }

  img {
    max-width: 100%;
  }

  figure, pre, .gallery {
    margin: calc(2 * var(--gutter)) calc(-2 * var(--gutter));

    @include mobile {
      margin-left: 0;
      margin-right: 0;
    }
  }

  figure img {
    height: auto;
    margin: 0;
  }

  figure iframe {
    background: var(--black);
    width: 100%;
    margin: 0;
    aspect-ratio: 16/10;
  }

  figure figcaption {
    @include font-size(small);

    text-align: center;
    color: var(--dimmed-text);
    margin-top: 1em;
    padding: 0 calc(2 * var(--gutter));

    &:empty {
      display: none;
    }
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--gutter) / 2);

    li {
      flex: 1;
      display: flex;
      aspect-ratio: 1;
      min-width: 25%;

      @include mobile {
        min-width: 33%;
      }
    }

    figure {
      margin: 0;
      width: 100%;
    }

    figure img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: var(--focus-x, 50%) var(--focus-y, 50%);
    }
  }

  code, pre {
    font-family: var(--font-family--monospace);
    background: var(--black);
    color: white;

    ::selection {
      background: var(--accent);
    }
  }

  code {
    font-size: 80%;
    vertical-align: 0.1em;
    padding: 0.25em 0.4em 0.2em;
    border-radius: 3px;
  }

  pre {
    position: relative;
    border-radius: 5px;

    code {
      @include invisible-scroll;
      display: block;
      padding: var(--gutter);
      color: inherit;
      background: none;
    }

    // Display highlighted language if any
    code[data-language]:not([data-language=text])::after {
      content: attr(data-language);
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.15em 0.5em;
      font-variant: small-caps;

      @include mobile {
        display: none;
      }
    }
  }

  blockquote {
    margin: 3em 0;
    font-size: 110%;
    line-height: 1.5;
    font-style: italic;

    .blockquote__content {
      margin-left: calc(-1 * var(--gutter));
      padding-left: var(--gutter);
      border-left: 1px solid;

      @include mobile {
        margin-left: 0;
        padding-right: var(--gutter);
      }
    }

    i, em, code {
      font-style: normal;
    }

    footer {
      @include font-size(small);
      margin-top: 1em;
      font-style: normal;
      text-align: right;
      &::before {
        content: '\2015';
      }
    }
  }
}
