@import '~highlight.js/styles/github-dark.css';
@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';
@import '~@splidejs/splide/dist/css/splide-core.min.css';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';

@mixin mobile {
  @media only screen and (max-width: 768px) { @content; }
}

@mixin desktop {
  @media only screen and (min-width: 768px) { @content; }
}

:root {
  $gutter: 3rem;
  $navbar-height: 5rem;

  --theme: var(--lightgray); // Re-defined in header.php

  --white: #FFFFFF;
  --black: #141414;
  --dimmed-text: #A5A5A5;

  --lightgray: #F0F0F0;
  --green: #74ffb0;
  --blue: #1ea1ed;
  --yellow: #f9eb0b;

  --font-family: 'Styrene', sans-serif;
  --font-family--monospace: 'Friction Mono', monospace;

  --font-size: 2.0rem;
  --line-height: 1.6;

  --font-size--small: 1.4rem;
  --line-height--small: 1.4;

  --font-size--large: 3.4rem;
  --line-height--large: 1.2;

  --font-size--xlarge: 6rem;
  --line-height--xlarge: 1.0;

  --gutter: #{$gutter};
  --vertical-spacing: #{$navbar-height + 2 * $gutter};
  --navbar-height: #{$navbar-height};

  --max-width: 1280px;
  --max-width--article: 75rem;

  @include mobile {
    $gutter: 2rem;
    $navbar-height: 4rem;
    --gutter: #{$gutter};
    --vertical-spacing: #{$navbar-height + $gutter};
    --navbar-height: #{$navbar-height};

    --font-size: 1.9rem;
    --font-size--large: 2.4rem;
    --font-size--xlarge: 3.8rem;
  }
}

@mixin font-size ($suffix: '') {
  font-size: var(--font-size--#{$suffix}, var(--font-size));
  line-height: var(--line-height--#{$suffix}, var(--line-height));
}

@import 'sass/base';

@import 'components/dom/Article';
@import 'components/dom/Button';
@import 'components/dom/Marquee';
@import 'components/dom/Menu';
@import 'components/dom/Paper';
@import 'components/dom/PaperInfo';
@import 'components/dom/Photoswipe';
@import 'components/dom/View';

@import 'components/svg/NutGrid';

@import 'views/contact';
@import 'views/footer';
@import 'views/home';
@import 'views/intro';
@import 'views/keywords';
@import 'views/playground';
@import 'views/project';
@import 'views/projects';
